import styled, { css, RuleSet } from 'styled-components';
import { HTMLInputTypeAttribute } from 'react';

const InputWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: ${convertVW(8)};
    width: 100%;

    user-select: none;
  `}
`;

const returnTextStyle = (
  convertVW: any,
  type: HTMLInputTypeAttribute | undefined,
  isError?: boolean
): RuleSet<object> => css`
  width: 100%;
  min-height: ${convertVW(46)};
  background: #ffffff;
  border: ${convertVW(1)} solid ${isError ? 'red' : '#e4e4e7'};
  border-radius: ${convertVW(8)};
  padding: ${convertVW(12)} ${convertVW(16)};
  outline: none;
  pointer-events: ${type === 'select' ? `none` : `unset`};

  font-family: Plus Jakarta Sans, sans-serif;
  font-size: ${convertVW(14)};
  line-height: ${convertVW(20)};
  color: rgba(10, 26, 37, 0.75);
`;

const InputText = styled.input<{ isError?: boolean }>`
  ${({ theme: { convertVW }, type, isError = false }) => css`
    position: relative;

    &[type='file']::file-selector-button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    &::placeholder {
      color: #959595;
    }

    ${returnTextStyle(convertVW, type, isError)}
  `}
`;

const InputTextArea = styled.textarea<{ isError?: boolean }>`
  ${({ theme: { convertVW }, isError = false }) => css`
    position: relative;

    &[type='file']::file-selector-button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    &::placeholder {
      color: #959595;
    }

    ${returnTextStyle(convertVW, undefined, isError)}
  `}
`;

const InputMultiSelect = styled.div`
  ${({ theme: { convertVW } }) => returnTextStyle(convertVW, 'multi-select')}
`;

const InputMultiSelectObjects = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: ${convertVW(16)};
    width: calc(100% - ${convertVW(32)});
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

const InputMultiSelectObject = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    align-items: flex-start;
    padding: ${convertVW(3)} ${convertVW(5)};
    gap: ${convertVW(6)};
    background: #e4e4e7;
    border: ${convertVW(1)} solid #e4e4e7;
    border-radius: ${convertVW(4)};
    width: fit-content;
    cursor: pointer;

    font-family: Plus Jakarta Sans, sans-serif;
    font-weight: 300;
    font-size: ${convertVW(18)};
    line-height: ${convertVW(24)};
    letter-spacing: ${convertVW(0.36)};
    color: #9c9c9c;
    white-space: nowrap;
  `}
`;

const InputMultiSelectObjectDelete = styled.div`
  font-family: Plus Jakarta Sans, sans-serif;
  font-weight: 700;
  color: #999999;
`;

const InputLabel = styled.label`
  ${({ theme: { convertVW } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(18)};
    line-height: ${convertVW(25)};
    color: #0a1a25;
  `}
`;

const InputIcon = styled.img`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    right: ${convertVW(12)};
    bottom: ${convertVW(13)};
    height: ${convertVW(20)};
  `}
`;

const InputPlaceholder = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(14)};
    line-height: ${convertVW(30)};
    color: #959595;
  `}
`;

export {
  InputWrapper as Wrapper,
  InputText as Text,
  InputTextArea as TextArea,
  InputMultiSelect as MultiSelect,
  InputMultiSelectObjects as MultiSelectObjects,
  InputMultiSelectObject as MultiSelectObject,
  InputMultiSelectObjectDelete as MultiSelectObjectDelete,
  InputLabel as Label,
  InputPlaceholder as Placeholder,
  InputIcon as Icon,
};
