import styled, { css } from 'styled-components';

const PaginationButtonWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${convertVW(4)};
    height: ${convertVW(44)};
    padding: 0 ${convertVW(7)};
    border: ${convertVW(1)} solid #e4e4e7;
    border-radius: ${convertVW(6)};
    cursor: pointer;
  `}
`;

const PaginationButtonColumn = styled.div`
  ${({ theme: { convertVW } }) => css`
    width: ${convertVW(6)};
    height: ${convertVW(21)};
    background-color: #d9d9d9;
  `}
`;

export { PaginationButtonWrapper as Wrapper, PaginationButtonColumn as Column };
