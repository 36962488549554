/** Dependencies */
import React, { FC } from 'react';

/** Images */
import Arrow from 'assets/images/svg/logo-arrow.svg';
import ShortBubble from 'assets/images/svg/short-bubble.svg';
import LongBubble from 'assets/images/svg/long-bubble.svg';
import BigCircle from 'assets/images/svg/big-circle.svg';
import MediumCircle from 'assets/images/svg/medium-circle.svg';
import SmallCircle from 'assets/images/svg/small-circle.svg';

/** Styles */
import * as S from './Loading.styled';

const Loading: FC = () => {
  return (
    <S.Wrapper>
      <S.Content>
        <S.Arrow src={Arrow} alt={'Arrow'} />
        <S.ShortBubbleContainer>
          <S.ShortBubble src={ShortBubble} alt={'Short Bubble'} />
        </S.ShortBubbleContainer>
        <S.LongBubbleContainer>
          <S.LongBubble src={LongBubble} alt={'Long Bubble'} />
        </S.LongBubbleContainer>
        <S.BigCircle src={BigCircle} alt={'Big Circle'} />
        <S.MediumCircle src={MediumCircle} alt={'Medium Circle'} />
        <S.SmallCircle src={SmallCircle} alt={'Small Circle'} />
      </S.Content>
    </S.Wrapper>
  );
};

export default Loading;
