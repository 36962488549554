import { useCallback } from 'react';

import { useDeviceDetect } from 'hooks/UseDeviceDetect';

import useDimensions from './UseDimensions';

interface ReturnType {
  convertVW: (sizeInPx: number) => string;
  convertPxToVh: (sizeInPx: number) => string;
}

const DeviceSizeMapping = {
  Mobile: 360,
  Desktop: 1920,
  WideDesktop: 3000,
  MobileHeight: 800,
};

const useMetricConverter = (): ReturnType => {
  const { isMobile, isWideDesktop } = useDeviceDetect();

  const { width, height } = useDimensions();

  const convertVW = useCallback(
    (sizeInPx: number): string => {
      const viewportWidth = isMobile
        ? DeviceSizeMapping.Mobile
        : isWideDesktop
        ? DeviceSizeMapping.WideDesktop
        : DeviceSizeMapping.Desktop;

      if (width >= 1920) {
        return `${sizeInPx}px`;
      } else if (width < 1920) {
        const itemSize = sizeInPx * 100;
        const sizeInVw = itemSize / viewportWidth;
        return `${sizeInVw}vw`;
      } else {
        return String(sizeInPx);
      }
    },
    [isMobile, isWideDesktop, width]
  );

  const convertPxToVh = useCallback(
    (sizeInPx: number): string => {
      const viewportHeight = DeviceSizeMapping.MobileHeight;

      if (height >= 1920) {
        return `${sizeInPx}px`;
      } else if (height < 1920) {
        const itemSize = sizeInPx * 100;
        const sizeInVh = itemSize / viewportHeight;
        return `${sizeInVh}vh`;
      } else {
        return String(sizeInPx);
      }
    },
    [height]
  );

  return {
    convertVW,
    convertPxToVh,
  };
};

export { useMetricConverter };
