/** Dependencies */
import React, { FC, useEffect, useRef, useState } from 'react';

/** Components */
import Tag from 'components/Tag/Tag';

/** Images */
import MinimizeIcon from 'assets/images/svg/minimize.svg';
import MaximizeIcon from 'assets/images/svg/maximize.svg';

/** Types */
import { ITag } from 'features/Header/types';

/** Styles */
import * as S from 'features/Tags/Tags.styled';

interface IProps {
  title: string;
  tags: ITag[];
  onSelect?: (selectedTags: ITag[]) => void;
}

const Tags: FC<IProps> = ({ title, tags, onSelect }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
  const [isMinimized, setIsMinimized] = useState(true);

  const handleTitleClick = (): void => {
    setIsMinimized(!isMinimized);
  };

  const handleTagClick = (tag: ITag): void => {
    const isTagSelectedBefore = selectedTags.some((t) => t.id === tag.id);

    isTagSelectedBefore
      ? setSelectedTags((list) =>
          list.filter((t) => t.id !== tag.id && t.text.toLowerCase() !== 'all')
        )
      : setSelectedTags([...selectedTags, tag]);
  };

  useEffect(() => {
    onSelect?.(selectedTags);
  }, [selectedTags]);

  return (
    <S.Wrapper>
      <S.Title onClick={handleTitleClick}>
        <S.TitleIcon src={isMinimized ? MaximizeIcon : MinimizeIcon} />
        <S.TitleText>{title}</S.TitleText>
      </S.Title>
      <S.Content isMinimized={isMinimized} ref={scrollRef}>
        <Tag
          isActive={selectedTags.length <= 0}
          onClick={() => setSelectedTags([])}
          scrollableRef={scrollRef}
        >
          All
        </Tag>
        {tags.map((tag, i) => (
          <Tag
            key={i}
            isActive={selectedTags.some((t) => t.id === tag.id)}
            onClick={() => handleTagClick(tag)}
            scrollableRef={scrollRef}
          >
            {tag.text}
          </Tag>
        ))}
      </S.Content>
    </S.Wrapper>
  );
};

export default Tags;
