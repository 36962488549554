import { Toast } from 'react-hot-toast';

export function capitalizeFirstLetters(string: string): string {
  const array = string.split(' ');

  const upperCaseArray = array.map(
    (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  return upperCaseArray.join(' ');
}

export function flattenResponse(response: any[] | any): any[] {
  const returnFlattenItem = (item: any): any => {
    const flattenedItem: any = { id: item.id, ...item.attributes };
    if (item?.attributes?.image && item?.attributes?.image?.data) {
      flattenedItem.image = {
        id: item.attributes.image.data.id,
        ...item.attributes.image.data.attributes,
        url: `${process.env.REACT_APP_BASE_URL}${item.attributes.image.data.attributes.url}`,
      };
    }
    if (item?.attributes?.icon && item?.attributes?.icon?.data) {
      flattenedItem.icon = {
        id: item.attributes.icon.data.id,
        ...item.attributes.icon.data.attributes,
        url: `${process.env.REACT_APP_BASE_URL}${item.attributes.icon.data.attributes.url}`,
      };
    }
    if (item?.attributes?.iconDark && item?.attributes?.iconDark?.data) {
      flattenedItem.iconDark = {
        id: item.attributes.iconDark.data.id,
        ...item.attributes.iconDark.data.attributes,
        url: `${process.env.REACT_APP_BASE_URL}${item.attributes.iconDark.data.attributes.url}`,
      };
    }
    if (
      item?.attributes?.designerUser &&
      item?.attributes?.designerUser?.data
    ) {
      flattenedItem.designerUser = {
        id: item.attributes.designerUser.data.id,
        ...item.attributes.designerUser.data.attributes,
      };
    }
    if (item?.attributes?.brand && item?.attributes?.brand?.data) {
      flattenedItem.brand = {
        id: item.attributes.brand.data.id,
        ...item.attributes.brand.data.attributes,
      };
    }
    if (item?.attributes?.category && item?.attributes?.category?.data) {
      flattenedItem.category = {
        id: item.attributes.category.data.id,
        ...item.attributes.category.data.attributes,
      };
    }
    if (item?.attributes?.children && item?.attributes?.children?.data) {
      flattenedItem.children = flattenResponse(item.attributes.children.data);
    }
    if (item?.attributes?.emailTags && item?.attributes?.emailTags?.data) {
      flattenedItem.emailTags = flattenResponse(item.attributes.emailTags.data);
    }
    if (item?.attributes?.industries && item?.attributes?.industries?.data) {
      flattenedItem.industries = flattenResponse(
        item.attributes.industries.data
      );
    }
    if (
      item?.attributes?.subCategories &&
      item?.attributes?.subCategories?.data
    ) {
      flattenedItem.subCategories = flattenResponse(
        item.attributes.subCategories.data
      );
    }
    return flattenedItem;
  };

  return Array.isArray(response)
    ? response?.map((item: any) => returnFlattenItem(item)) ?? []
    : returnFlattenItem(response);
}

export const compareUsers = ({
  user,
  outsetaUser,
}: {
  user: any;
  outsetaUser: any;
}): boolean =>
  user.fullName !== outsetaUser.FullName ||
  user.email !== outsetaUser.Email ||
  user.plan !== outsetaUser?.Account?.CurrentSubscription?.Plan?.Name;

export const toastOptions = (icon?: string): Toast => {
  return {
    duration: 2000,
    position: 'bottom-center',
    style: {
      background: '#2c2c2c',
      color: 'white',
    },
    icon,
  } as Toast;
};

export const shuffle = (array: any[]): void => {
  let currentIndex = array?.length ?? 0;

  while (currentIndex != 0) {
    const randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
};

export const sort = (array: any[], sortOption: any): void => {
  if (sortOption.key === 'random') {
    shuffle(array);
  } else
    array?.sort((cardA, cardB) => {
      if (
        (cardA as any)[sortOption.key].charAt(0) >
        (cardB as any)[sortOption.key].charAt(0)
      ) {
        return sortOption.order;
      }
      if (
        (cardB as any)[sortOption.key].charAt(0) >
        (cardA as any)[sortOption.key].charAt(0)
      ) {
        return -sortOption.order;
      }

      return 0;
    });
};

export const toDataURL = (url: string): Promise<any> => {
  return fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );
};

export const dataURLtoFile = (dataUrl: string, filename: string): File => {
  const arr = dataUrl.split(',');
  const mime = arr[0]?.match(/:(.*?);/)?.[1];
  const bString = atob(arr[1]);
  let n = bString.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bString.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const deepEqual = (obj1: any, obj2: any): boolean => {
  // Check if both are the same reference
  if (obj1 === obj2) {
    return true;
  }

  // Check if either of them is null or undefined
  if (obj1 == null || obj2 == null) {
    return false;
  }

  // Check if they are not the same type
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  // If they are arrays
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }
    for (let i = 0; i < obj1.length; i++) {
      if (!deepEqual(obj1[i], obj2[i])) {
        return false;
      }
    }
    return true;
  }

  // If they are objects
  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  }

  // If they are primitive types, compare their values
  return obj1 === obj2;
};
