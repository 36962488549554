import styled, { css } from 'styled-components';
import { BreakPoint } from 'constants/BreakPoint';

const SortButtonWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${convertVW(32)};
    width: ${convertVW(200)};
    cursor: pointer;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      gap: ${convertVW(12)};
    }
  `}
`;

export { SortButtonWrapper as Wrapper };
