import styled, { css } from 'styled-components';
import { ButtonS } from 'components/Button';

const CopyLimitModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CopyLimitModalParagraph = styled.p``;

const CopyLimitButtonsContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    gap: ${convertVW(8)};
    margin-top: ${convertVW(24)};
    margin-bottom: ${convertVW(-19.2)};

    ${ButtonS.Wrapper} {
      border: none;
    }
  `}
`;

export {
  CopyLimitModalWrapper as Wrapper,
  CopyLimitModalParagraph as Paragraph,
  CopyLimitButtonsContainer as ButtonsContainer,
};
