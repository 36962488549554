interface IBreakPoint {
  SmallMobile: number;
  Mobile: number;
  Tablet: number;
  Desktop: number;
  DesktopHeight: number;
  WideDesktop: number;
  UltraWideDesktop: number;
}

export const BreakPoint: IBreakPoint = {
  SmallMobile: 320,
  Mobile: 768,
  Tablet: 1024,
  Desktop: 1920,
  DesktopHeight: 1080,
  WideDesktop: 1921,
  UltraWideDesktop: 2560,
};
