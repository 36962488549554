export const lightTheme = {
  sidebar_bg: '#E6EDF1',
  parent_bg: '#F4F6FA',
  child_bg: '#F4F6FA',
  parent_text: '#000000',
  child_text: '#0A1A25',
  child_text_hover: '#03090b',
};

export const darkTheme = {
  sidebar_bg: '#2c2c2c',
  parent_bg: '#4A5878',
  child_bg: '#4A5878',
  parent_text: '#fefefe',
  child_text: 'rgba(255, 255, 255, 0.75)',
  child_text_hover: '#ffffff',
};
