import styled, { css } from 'styled-components';
import { BreakPoint } from 'constants/BreakPoint';
import TickIcon from 'assets/images/svg/tick.svg';

const SelectWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: ${convertVW(8)};
    width: 100%;
    cursor: pointer;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      gap: ${convertVW(12)};
    }
  `}
`;

const SelectList = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    top: calc(100% + ${convertVW(4)});
    left: 0;
    width: 100%;
    max-height: ${convertVW(200)};
    border-radius: ${convertVW(6)};
    border: ${convertVW(1)} solid #e4e4e7;
    background-color: white;
    overflow: scroll;
    z-index: 1;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      position: relative;
      top: 0;
    }
  `}
`;

const SelectListItem = styled.div<{ isSelected: boolean }>`
  ${({ theme: { convertVW }, isSelected }) => css`
    position: relative;
    user-select: none;
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(11)};
    line-height: ${convertVW(20)};
    color: #0a1a25;
    padding: ${convertVW(12)} ${convertVW(24)};

    &:before {
      content: '';
      display: ${isSelected ? '' : 'none'};
      background: url(${TickIcon}) no-repeat;
      background-size: contain;
      position: absolute;
      right: ${convertVW(24)};
      top: 50%;
      transform: translateY(-50%);
      width: ${convertVW(15)};
      height: ${convertVW(15)};
    }

    &:hover {
      background-color: #eee;
    }
  `}
`;

export {
  SelectWrapper as Wrapper,
  SelectList as List,
  SelectListItem as ListItem,
};
