import styled, { css } from 'styled-components';

const DashboardWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: relative;
    padding: ${convertVW(32)};
    width: 100%;
  `}
`;

const DashboardTable = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #eee;
    border-radius: ${convertVW(8)};
    gap: ${convertVW(2)};
    padding: 0;
    box-shadow: 0 ${convertVW(8)} ${convertVW(12)} ${convertVW(-2)}
        rgba(82, 82, 82, 0.1),
      0 ${convertVW(4)} ${convertVW(4)} ${convertVW(-4)} rgba(82, 82, 82, 0.1);
  `}
`;

const DashboardHeader = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: grid;
    grid-template-columns: 10% 10% 10% 10% 40% 20%;
    padding: 0 ${convertVW(32)};
    height: ${convertVW(56)};
  `}
`;

const DashboardRow = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: grid;
    grid-template-columns: 10% 10% 10% 10% 40% 20%;
    align-items: center;
    height: ${convertVW(80)};
    padding: 0 ${convertVW(32)};
    background-color: white;
  `}
`;

const DashboardHeaderItem = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: Plus Jakarta Sans, sans-serif;
    font-weight: 900;
    font-size: ${convertVW(14)};
    line-height: ${convertVW(15)};
    letter-spacing: ${convertVW(1.5)};
    text-transform: uppercase;
    color: #a26df7;
  `}
`;

const DashboardRowItem = styled.div<{ isAlignStart?: boolean }>`
  ${({ theme: { convertVW }, isAlignStart = true }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${isAlignStart ? 'flex-start' : 'flex-end'};
    background: ${isAlignStart ? 'transparent' : 'transparent'};
    gap: ${convertVW(8)};
  `}
`;

const DashboardAmount = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-weight: 600;
    font-size: ${convertVW(16)};
    line-height: ${convertVW(17)};
    text-transform: uppercase;
    color: #25213b;
  `}
`;

const DashboardMetric = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-weight: 300;
    font-size: ${convertVW(14)};
    line-height: ${convertVW(17)};
    text-transform: uppercase;
    color: #25213b;
  `}
`;

const DashboardStatus = styled.div<{ isPaid: boolean }>`
  ${({ theme: { convertVW }, isPaid }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${convertVW(4)} ${convertVW(8)};
    border-radius: ${convertVW(12)};
    gap: ${convertVW(4)};

    font-family: Plus Jakarta Sans, sans-serif;
    font-weight: 600;
    font-size: ${convertVW(12)};
    line-height: ${convertVW(17)};
    background-color: ${isPaid
      ? 'rgba(102,217,150,0.2)'
      : 'rgba(237,114,74,0.2)'};
    color: ${isPaid ? '#66D996' : '#ED724A'};

    .dot {
      width: ${convertVW(6)};
      height: ${convertVW(6)};
      border-radius: 50%;
      background-color: ${isPaid ? '#66D996' : '#ED724A'};
    }
  `}
`;

const DashboardMessage = styled.div`
  ${({ theme: { convertVW } }) => css`
    height: ${convertVW(80)};
    overflow: auto;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${convertVW(8)} ${convertVW(8)} ${convertVW(8)} 0;

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export {
  DashboardWrapper as Wrapper,
  DashboardTable as Table,
  DashboardHeader as Header,
  DashboardRow as Row,
  DashboardRowItem as RowItem,
  DashboardHeaderItem as HeaderItem,
  DashboardAmount as Amount,
  DashboardMetric as Metric,
  DashboardStatus as Status,
  DashboardMessage as Message,
};
