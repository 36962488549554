/** Dependencies */
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

/** Components */
import Select from 'components/Select/Select';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';

/** Utilities */
import { flattenResponse, toastOptions } from 'utilities/functions';

/** Store */
import { ISortItem } from 'store/slices/Sort.slice';

/** Api */
import { getRequest, postRequest, putRequest } from 'api/apiClient';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Styles */
import * as S from './Transact.styled';

const Transact: FC = () => {
  const [selectedListItem, setSelectedListItem] = useState<any>();
  const [selectedDesigner, setSelectDesigner] = useState<any>();
  const [designers, setDesigners] = useState<any[]>([]);
  const [designersList, setDesignersList] = useState<any[]>([]);
  const [fee, setFee] = useState('0');
  const [message, setMessage] = useState('');
  const [newBalance, setNewBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getDesigners = async (): Promise<void> => {
    const designersResponse = await getRequest(ENDPOINTS.DESIGNER_USERS);

    setDesigners(flattenResponse(designersResponse.data.data));
  };

  const handleSelectDesigner = (designer?: ISortItem): void => {
    setSelectedListItem(designer);
    setSelectDesigner(
      designers?.find((user) => designer?.key === user.id) ?? null
    );
  };

  const handleFeeChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFee(e.target.value);
    setNewBalance(
      (selectedDesigner?.totalBalance ?? 0) - Number(e.target.value)
    );
  };

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true);

    await postRequest(ENDPOINTS.TRANSACTIONS, {
      designerUser: [selectedDesigner.id],
      balanceDelta: -fee,
      totalBalance: newBalance,
      message,
    });

    await putRequest(ENDPOINTS.DESIGNER_USER_WITH_ID(selectedDesigner.id), {
      totalBalance: newBalance,
    });

    await getDesigners();
    handleSelectDesigner();
    setFee('0');
    setMessage('');
    setNewBalance(0);
    toast('Successfully paid', toastOptions('👏'));
    setIsLoading(false);
  };

  useEffect(() => {
    void getDesigners();
  }, []);

  useEffect(() => {
    setDesignersList(
      designers.map((user: any) => {
        const designsCount = user.designs.data.length;

        return {
          key: user.id,
          text: `${user.fullName} (${user.designs.data.length} design${
            designsCount > 1 && 's'
          })`,
          order: '',
        };
      })
    );
  }, [designers]);

  return (
    <S.Wrapper>
      <S.Form>
        <Select
          name={'Designers'}
          list={designersList}
          onChange={handleSelectDesigner}
          selectedValue={selectedListItem}
          placeholder={'Select designer'}
        />
        <S.Results>
          <Input
            name={'Total Balance'}
            defaultValue={selectedDesigner?.totalBalance ?? 0}
            disabled
          />
          <Input
            name={'Fixed Fee'}
            defaultValue={selectedDesigner?.fixedFee ?? 0}
            disabled
          />
        </S.Results>
        <Input
          type={'textarea'}
          name={'Message to designer'}
          placeholder={'message'}
          onChangeTextArea={(e) => setMessage(e.target.value)}
          defaultValue={message}
        />
        <Input
          name={'Designer Fee'}
          defaultValue={fee.toString()}
          onChange={handleFeeChange}
        />
        <Input
          name={'Balance'}
          defaultValue={newBalance.toString()}
          onChange={handleFeeChange}
          disabled
        />
        <Button
          isDisabled={isLoading}
          text={isLoading ? 'Loading...' : 'Pay'}
          onClick={handleSubmit}
        />
      </S.Form>
    </S.Wrapper>
  );
};

export default Transact;
