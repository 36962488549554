import styled, { css } from 'styled-components';

const TransactWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TransactForm = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: ${convertVW(24)};
    gap: ${convertVW(24)};
  `}
`;

const TransactResults = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${convertVW(24)};
    width: 100%;
  `}
`;

export {
  TransactWrapper as Wrapper,
  TransactForm as Form,
  TransactResults as Results,
};
