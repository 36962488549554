import { RefObject, useEffect, useState } from 'react';

function useIsInView({
  scrollableRef,
  elementRef,
}: {
  scrollableRef: RefObject<HTMLDivElement>;
  elementRef: RefObject<HTMLElement>;
}): {
  isInView: boolean;
} {
  const [isInView, setIsInView] = useState(false);

  const onScroll = (): void => {
    if (!elementRef.current || !scrollableRef.current) {
      setIsInView(false);
      return;
    }

    const elementRect = elementRef.current.getBoundingClientRect();
    const scrollableRect = scrollableRef.current.getBoundingClientRect();

    const isHorizontallyInView =
      elementRect.left >= scrollableRect.left - 10 &&
      elementRect.right <= scrollableRect.right + 10;

    const isVerticallyInView = elementRect.bottom <= scrollableRect.bottom + 20;

    setIsInView(isHorizontallyInView && isVerticallyInView);
  };

  useEffect(() => {
    onScroll();
    document.addEventListener('scroll', onScroll, true);
    return () => {
      document.removeEventListener('scroll', onScroll, true);
    };
  });

  return { isInView };
}

export { useIsInView };
