/** Dependencies */
import React, { FC, useEffect } from 'react';

/** Hooks */
import { useAppSelector } from 'hooks/UseAppSelector';
import { useAppDispatch } from 'hooks/UseAppDispatch';
import { useDeviceDetect } from 'hooks/UseDeviceDetect';

/** Store */
import {
  incrementColumCount,
  selectColumnCount,
  setColumCount,
} from 'store/slices/Pagination.slice';

/** Styles */
import * as S from 'features/PaginationButton/PaginationButton.styled';

const PaginationButton: FC = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = useDeviceDetect();

  const columnCount = useAppSelector(selectColumnCount);

  const handleClick = (): void => {
    dispatch(incrementColumCount());
  };

  useEffect(() => {
    if (isMobile) dispatch(setColumCount(4));
  }, [isMobile]);

  return (
    <S.Wrapper onClick={handleClick}>
      {Array.from(Array(columnCount).keys()).map((_, i) => (
        <S.Column key={i} />
      ))}
    </S.Wrapper>
  );
};

export default PaginationButton;
