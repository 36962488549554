/** Dependencies */
import React, { FC } from 'react';

type props = {
  children: React.ReactNode;
  textToCopy: string;
  onCopy: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const CopyToClipboardHTML: FC<props> = ({ children, textToCopy, onCopy }) => {
  const copyToClipboard = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): boolean | undefined => {
    e.preventDefault();
    document.addEventListener('copy', (event: ClipboardEvent) => {
      event.clipboardData?.setData('text/html', textToCopy);
      event.preventDefault();
    });
    if (!document.execCommand('copy')) {
      const textarea = document.createElement('textarea');
      textarea.textContent = textToCopy;
      textarea.style.position = 'fixed';
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand('copy');
      } catch (ex) {
        window.alert('Copy to clipboard failed, please contact support');
        console.warn('Copy to clipboard failed.', ex);
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
    onCopy(e);
  };

  return <div onClick={copyToClipboard}>{children}</div>;
};

export default CopyToClipboardHTML;
