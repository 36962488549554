import styled, { css } from 'styled-components';
import Input from 'components/Input/Input';
import * as InputS from 'components/Input/Input.styled';

const ApproveModalWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: ${convertVW(12)};

    ${InputS.TextArea} {
      max-width: 100%;
      max-height: ${convertVW(200)};
      min-width: ${convertVW(500)};
    }
  `}
`;

const ApproveModalText = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-size: ${convertVW(18)};
    width: ${convertVW(500)};

    span {
      font-weight: bolder;
    }
  `}
`;

const ApproveModalMessage = styled(Input)``;

const ApproveModalButtons = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${convertVW(12)};
    margin-top: ${convertVW(64)};
  `}
`;

export {
  ApproveModalWrapper as Wrapper,
  ApproveModalText as Text,
  ApproveModalMessage as Message,
  ApproveModalButtons as Buttons,
};
