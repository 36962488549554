/** Dependencies */
import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';

/** Api */
import { getRequest } from 'api/apiClient';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Utilities */
import { flattenResponse } from 'utilities/functions';

/** Store */
import { setModal } from 'store/slices/Modal.slice';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Hooks */
import { useAppDispatch } from 'hooks/UseAppDispatch';

/** Styles */
import * as S from './Dashboard.styled';

const Dashboard: FC = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  const [transactions, setTransactions] = useState<any[]>([]);

  const getTransactions = async (): Promise<void> => {
    const response = await getRequest(ENDPOINTS.MY_TRANSACTIONS(user.Uid));

    setTransactions(flattenResponse(response.data.data));
  };

  const handleMessageClick = (message: string): void => {
    dispatch(
      setModal({
        isVisible: true,
        title: `Message From Admin`,
        content: <div>{message}</div>,
      })
    );
  };

  useEffect(() => {
    void getTransactions();
  }, []);

  return (
    <S.Wrapper>
      <S.Table>
        <S.Header>
          <S.HeaderItem>DELTA</S.HeaderItem>
          <S.HeaderItem>BALANCE</S.HeaderItem>
          <S.HeaderItem>NEW BALANCE</S.HeaderItem>
          <S.HeaderItem>STATUS</S.HeaderItem>
          <S.HeaderItem>MESSAGE</S.HeaderItem>
          <S.HeaderItem>DATE</S.HeaderItem>
        </S.Header>
        {transactions.map((transaction, i) => (
          <S.Row key={i}>
            <S.RowItem>
              <S.Amount>
                {transaction.balanceDelta < 0 ? '-' : ''}$
                {transaction.balanceDelta < 0
                  ? Math.abs(transaction.balanceDelta)
                  : transaction.balanceDelta}
              </S.Amount>
              <S.Metric>USD</S.Metric>
            </S.RowItem>
            <S.RowItem>
              <S.Amount>
                ${transaction.totalBalance - transaction.balanceDelta}
              </S.Amount>
              <S.Metric>USD</S.Metric>
            </S.RowItem>
            <S.RowItem>
              <S.Amount>${transaction.totalBalance}</S.Amount>
              <S.Metric>USD</S.Metric>
            </S.RowItem>
            <S.RowItem>
              <S.Status isPaid={transaction.balanceDelta < 0}>
                <div className={'dot'} />
                {transaction.balanceDelta < 0 ? 'Paid' : 'Approved'}
              </S.Status>
            </S.RowItem>
            <S.RowItem onClick={() => handleMessageClick(transaction.message)}>
              <S.Message>{transaction.message}</S.Message>
            </S.RowItem>
            <S.RowItem>
              <S.Amount>{moment(transaction.createdAt).format('LLL')}</S.Amount>
            </S.RowItem>
          </S.Row>
        ))}
      </S.Table>
    </S.Wrapper>
  );
};

export default Dashboard;
