import styled, { css } from 'styled-components';

const ToggleWrapper = styled.div<{ background: string }>`
  ${({ theme: { convertVW }, background }) => css`
    position: relative;
    width: ${convertVW(68)};
    height: ${convertVW(23.57)};
    background: url(${background}) no-repeat center;
    background-size: contain;
    border-radius: ${convertVW(14.51)};
    cursor: pointer;

    user-select: none;
  `}
`;

const ToggleKnob = styled.img<{ isActive: boolean }>`
  ${({ theme: { convertVW }, isActive }) => css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: ${convertVW(18.86)};
    height: ${convertVW(18.86)};
    border-radius: 50%;

    ${isActive
      ? css`
          right: ${convertVW(2.36)};
        `
      : css`
          left: ${convertVW(2.36)};
        `}
  `}
`;

export { ToggleWrapper as Wrapper, ToggleKnob as Knob };
