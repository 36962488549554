/** Dependencies */
import React, { FC } from 'react';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Components */
import Button from 'components/Button/Button';

/** Store */
import { setModal } from 'store/slices/Modal.slice';

/** Hooks */
import { useAppDispatch } from 'hooks/UseAppDispatch';

/** Styles */
import * as S from './CopyLimitModal.styled';

const CopyLimitModal: FC = () => {
  const { openProfile, plans, user } = useAuth();
  const dispatch = useAppDispatch();

  const selectedPlan = plans.find(
    (plan) => plan.Uid === user?.Account?.CurrentSubscription?.Plan?.Uid
  );

  const isBasic = selectedPlan?.Name === 'Basic';

  const handleUpgradeClick = (): void => {
    dispatch(setModal({ isVisible: false }));
    !isBasic && openProfile({ tab: 'planChange' });
  };

  const handleRemindClick = (): void => {
    dispatch(setModal({ isVisible: false }));
    isBasic && openProfile({ tab: 'planChange' });
  };

  return (
    <S.Wrapper>
      <S.Paragraph>
        {isBasic
          ? 'You’ve used all 10 of your daily copies. Your limit will reset tomorrow, allowing you to copy more files and continue your work.'
          : 'You’ve reached your copy limit of 10 copies. Upgrade to a Basic plan for more flexibility and increase your daily copy limit to 10 files per day! To Pro plan to be totally free from restrictions!'}
      </S.Paragraph>

      <S.Paragraph>
        {isBasic
          ? 'Thank you for being a Basic user! We appreciate your support, and we’re always here to help you make the most of your subscription.'
          : 'Unlock more features and streamline your workflow with the Pro subscription or the Basic subscription. Ready to make the switch?'}
      </S.Paragraph>

      <S.ButtonsContainer>
        <Button
          text={isBasic ? 'Got It' : 'Upgrade Now'}
          onClick={handleUpgradeClick}
          bgColor={'#66D996'}
        />
        <Button
          isOutline
          text={isBasic ? 'Contact Support' : 'Remind Me Later'}
          onClick={handleRemindClick}
        />
      </S.ButtonsContainer>
    </S.Wrapper>
  );
};

export default CopyLimitModal;
