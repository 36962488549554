/** Dependencies */
import React, { FC, useEffect, useState } from 'react';

/** Utilities */
import { flattenResponse } from 'utilities/functions';

/** Components */
import Select from 'components/Select/Select';

/** Hooks */
import { useAppSelector } from 'hooks/UseAppSelector';
import { useAppDispatch } from 'hooks/UseAppDispatch';

/** Store */
import {
  ISortItem,
  selectSortItem,
  setSortItem,
} from 'store/slices/Sort.slice';

/** Api */
import { getRequest } from 'api/apiClient';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Styles */
import * as S from 'features/SortButton/SortButton.styled';

const SortButton: FC = () => {
  const dispatch = useAppDispatch();
  const selectedListItem = useAppSelector(selectSortItem);

  const [list, setList] = useState<ISortItem[]>([]);

  const getSortList = async (): Promise<void> => {
    const response = await getRequest(ENDPOINTS.SORTS);

    dispatch(
      setSortItem(
        flattenResponse(response.data.data).find(
          (item) => item.text === 'Newest'
        )
      )
    );

    setList(flattenResponse(response.data.data));
  };

  const handleSelectChange = (listItem: ISortItem): void => {
    dispatch(setSortItem(listItem));
  };

  useEffect(() => {
    void getSortList();
  }, []);

  return (
    <S.Wrapper>
      <Select
        list={list}
        onChange={handleSelectChange}
        selectedValue={selectedListItem}
        isArrowShown
      />
    </S.Wrapper>
  );
};

export default SortButton;
