import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/App.store';

interface IPaginationState {
  columnCount: number;
}

const initialState: IPaginationState = {
  columnCount: 4,
};

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    incrementColumCount: (state) => {
      state.columnCount = state.columnCount === 4 ? 2 : state.columnCount + 1;
    },
    setColumCount: (state, action) => {
      state.columnCount = action.payload;
    },
  },
});

export const { incrementColumCount, setColumCount } = paginationSlice.actions;

export const selectColumnCount = (state: RootState): number =>
  state.pagination.columnCount;

export default paginationSlice.reducer;
