import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/App.store';
import { ReactElement } from 'react';

interface IModalState {
  isVisible: boolean;
  title: string;
  content: ReactElement | null;
}

const initialState: IModalState = {
  isVisible: false,
  title: '',
  content: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.isVisible = action.payload.isVisible;
      state.title = action.payload.title;
      state.content = action.payload.content;
    },
  },
});

export const { setModal } = modalSlice.actions;

export const selectModal = (state: RootState): IModalState => state.modal;

export default modalSlice.reducer;
