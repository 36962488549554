/** Dependencies */
import React, { FC } from 'react';

/** Styles */
import * as S from './Toggle.styled';

interface IProps {
  isActive: boolean;
  knobBackground: string;
  background: string;
  onClick: () => void;
}

const Toggle: FC<IProps> = ({
  isActive,
  knobBackground,
  background,
  onClick,
}) => {
  return (
    <S.Wrapper background={background} onClick={onClick}>
      <S.Knob src={knobBackground} isActive={isActive} />
    </S.Wrapper>
  );
};

export default Toggle;
