const ROUTE = Object.freeze({
  ROOT: '/',
  EDIT: (id: number) => `/edit/${id}`,
  EDIT_TYPE: '/edit/:id',
  UPLOAD: '/upload',
  EMAILS: '/',
  AUTOMATION_FLOWS: '/automation-flows',
  INDUSTRY: '/industry',
  SPY_BRANDS: '/spy-brands',
  MY_SAVED_LIBRARY: '/my-saved-library',
  DASHBOARD: '/',
  HELP: '/help',
  TUTORIALS: '/tutorials',
  MY_PENDING_DESIGNS: '/my-pending-designs',
  MY_APPROVED_DESIGNS: '/my-approved-designs',
  MY_DISAPPROVED_DESIGNS: '/my-disapproved-designs',
  APPROVED_DESIGNS: '/',
  DISAPPROVED_DESIGNS: '/disapproved-designs',
  PENDING_DESIGNS: '/pending-designs',
  ARCHIVE: '/archive',
  TRANSACT: '/transact',
});

export { ROUTE };
