import styled, { css } from 'styled-components';

const PasteFigmaInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const PasteFigmaInputRemoveButton = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    bottom: ${convertVW(23)};
    transform: translateY(50%);
    right: ${convertVW(12)};
    cursor: pointer;

    font-size: ${convertVW(16)};
    line-height: ${convertVW(24)};
    letter-spacing: ${convertVW(0.36)};
    font-family: Plus Jakarta Sans, sans-serif;
    font-weight: 700;
    color: #999999;
  `}
`;

export {
  PasteFigmaInputWrapper as Wrapper,
  PasteFigmaInputRemoveButton as RemoveButton,
};
