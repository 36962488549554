import styled, { css } from 'styled-components';
import { ZINDEX } from 'constants/Style';

const ModalOverlay = styled.div`
  ${() => css`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: ${ZINDEX.modal};
    background: rgba(0, 0, 0, 0.3);
  `}
`;

const ModalWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 20%;
    min-height: 20%;
    max-height: 60%;
    max-width: ${convertVW(800)};
    border-radius: ${convertVW(4)};
    padding: ${convertVW(24)};
    background-color: white;
  `}
`;

const ModalCloseIcon = styled.img`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    top: ${convertVW(24)};
    right: ${convertVW(24)};
    width: ${convertVW(24)};
    height: ${convertVW(24)};
    transform: rotate(45deg);
    cursor: pointer;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  `}
`;

const ModalTitle = styled.div`
  ${({ theme: { convertVW } }) => css`
    width: 100%;

    font-size: ${convertVW(32)};
    font-weight: bold;
    line-height: ${convertVW(40)};
    color: black;
    letter-spacing: 0;
    text-align: left;
  `}
`;

const ModalContent = styled.div`
  ${({ theme: { convertVW } }) => css`
    padding: ${convertVW(24)};
  `}
`;

export {
  ModalOverlay as Overlay,
  ModalWrapper as Wrapper,
  ModalCloseIcon as CloseIcon,
  ModalTitle as Title,
  ModalContent as Content,
};
