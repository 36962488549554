/** Dependencies */
import React, { FC, ReactElement, useRef } from 'react';
import { createPortal } from 'react-dom';

/** Images */
import CloseIcon from 'assets/images/svg/maximize.svg';

/** Hooks */
import { useOnClickOutside } from 'hooks/UseOnClickOutside';
import { useAppSelector } from 'hooks/UseAppSelector';
import { useAppDispatch } from 'hooks/UseAppDispatch';

/** Store */
import { selectModal, setModal } from 'store/slices/Modal.slice';

/** Styles */
import * as S from './Modal.styled';

const Modal: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { isVisible, title, content } = useAppSelector(selectModal);

  const handleClose = (): void => {
    dispatch(setModal({ isVisible: false }));
  };

  useOnClickOutside(ref, () => {
    handleClose();
  });

  const returnModal = (): ReactElement => {
    return (
      <S.Overlay>
        <S.Wrapper ref={ref}>
          <S.CloseIcon onClick={handleClose} src={CloseIcon} alt={'close'} />
          <S.Title>{title}</S.Title>
          <S.Content>{content}</S.Content>
        </S.Wrapper>
      </S.Overlay>
    );
  };

  return isVisible
    ? createPortal(
        returnModal(),
        document.getElementById('modal-container') as HTMLElement
      )
    : null;
};

export default Modal;
