/** Dependencies */
import React, { FC, RefObject, useRef } from 'react';

/** Hooks */
import { useIsInView } from 'hooks/UseIsInView';
import { useDeviceDetect } from 'hooks/UseDeviceDetect';

/** Styles */
import * as S from './Tag.styled';

interface IProps {
  children: string;
  isActive: boolean;
  onClick: () => void;
  scrollableRef: RefObject<HTMLDivElement>;
}

const Tag: FC<IProps> = ({ children, isActive, onClick, scrollableRef }) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useDeviceDetect();

  const { isInView } = useIsInView({
    scrollableRef,
    elementRef,
  });

  return (
    <S.Wrapper
      ref={elementRef}
      onClick={onClick}
      isActive={isActive}
      isVisible={isMobile || isInView}
    >
      {children}
    </S.Wrapper>
  );
};

export default Tag;
