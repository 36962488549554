import { useMediaQuery } from './UseMediaQuery';
import { BreakPoint } from '../constants/BreakPoint';

interface ReturnType {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isWideDesktop: boolean;
}

export const useDeviceDetect = (): ReturnType => {
  const isMobile = useMediaQuery(`(max-width: ${BreakPoint.Mobile}px)`);
  const isTablet = useMediaQuery(`(max-width: ${BreakPoint.Tablet}px)`);
  const isDesktop = useMediaQuery(`(max-width: ${BreakPoint.Desktop}px)`);
  const isWideDesktop = useMediaQuery(
    `(min-width: ${BreakPoint.WideDesktop}px)`
  );

  return { isMobile, isTablet, isDesktop, isWideDesktop };
};
