import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const TabWrapper = styled(Link)`
  width: 100%;

  text-decoration: none;
`;

const TabContent = styled.div<{ isactive: boolean }>`
  ${({ theme: { convertVW, parent_bg, parent_text }, isactive }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${convertVW(12)};
    width: 100%;
    padding: 0 ${convertVW(24)};
    height: ${convertVW(34)};
    cursor: pointer;
    transition: all 0.2s;
    background-color: ${isactive ? parent_bg : 'transparent'};

    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(16)};
    line-height: ${convertVW(16)};
    font-weight: 600;
    color: ${parent_text};
  `}
`;

const TabChild = styled(Link)<{ isactive: boolean }>`
  ${({ theme: { convertVW, child_bg }, isactive }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0 ${convertVW(24)};
    height: ${convertVW(34)};
    cursor: pointer;
    transition: all 0.2s;
    background-color: ${isactive ? child_bg : 'transparent'};

    text-decoration: none;
  `}
`;

const TabChildText = styled.div`
  ${({ theme: { convertVW, child_text, child_text_hover } }) => css`
    height: 100%;
    overflow: hidden;

    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(14)};
    line-height: ${convertVW(14)};
    color: ${child_text};
    white-space: nowrap;
    text-overflow: ellipsis;
    align-content: center;

    &:hover {
      color: ${child_text_hover};
    }
  `}
`;

const TabIcon = styled.img`
  ${({ theme: { convertVW } }) => css`
    max-width: ${convertVW(18)};
    max-height: ${convertVW(18)};
  `}
`;

const TabCount = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: ${convertVW(20)};
    height: ${convertVW(20)};
    margin-left: ${convertVW(8)};
    padding-bottom: ${convertVW(1)};
    border-radius: 50%;

    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(12)};
    line-height: ${convertVW(14)};
    font-weight: 900;
    white-space: nowrap;
    color: #ec724a;
  `}
`;

const TabComingSoon = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 0 ${convertVW(12)};
    margin-left: ${convertVW(8)};
    border-radius: ${convertVW(99)};

    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(9)};
    line-height: ${convertVW(14)};
    white-space: nowrap;
    color: #000000;
  `}
`;

export {
  TabWrapper as Wrapper,
  TabContent as Content,
  TabChild as Child,
  TabChildText as ChildText,
  TabIcon as Icon,
  TabCount as Count,
  TabComingSoon as ComingSoon,
};
