import styled, { css } from 'styled-components';
import { BreakPoint } from 'constants/BreakPoint';
import * as CardS from './Card.styled';

const CardWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: relative;
    aspect-ratio: 2 / 3;
    background: #eee;
    border-radius: ${convertVW(8)};
    padding: ${convertVW(16)} ${convertVW(16)} ${convertVW(12)};
    box-shadow: 0 ${convertVW(8)} ${convertVW(12)} ${convertVW(-2)}
        rgba(82, 82, 82, 0.1),
      0 ${convertVW(4)} ${convertVW(4)} ${convertVW(-4)} rgba(82, 82, 82, 0.1);

    ${CardS.UserInfo} {
      display: none;
    }

    ${CardS.Message} {
      display: none;
    }

    &:hover {
      ${CardS.UserInfo} {
        display: flex;
      }

      ${CardS.Message} {
        display: flex;
      }
    }
  `}
`;

const CardInfoIcon = styled.img`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    right: ${convertVW(24)};
    bottom: ${convertVW(104)};
    width: ${convertVW(25)};
    height: ${convertVW(25)};
    background-color: #2e2e2e;
    border-radius: 50%;
    padding: ${convertVW(5)};
  `}
`;

const CardEditIcon = styled.img`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    right: ${convertVW(24)};
    top: ${convertVW(24)};
    width: ${convertVW(50)};
    height: ${convertVW(50)};
    background-color: #2e2e2e;
    border-radius: 50%;
    padding: ${convertVW(10)};
    cursor: pointer;
  `}
`;

const CardImageWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    width: 100%;
    height: calc(100% - ${convertVW(80)});
    overflow: scroll;
    border-radius: ${convertVW(6)};

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

const CardImage = styled.img`
  width: 100%;
`;

const CardUserInfo = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    left: 0;
    bottom: ${convertVW(80)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #eee;
    width: 100%;
    padding: ${convertVW(24)} ${convertVW(16)} ${convertVW(24)};
    gap: ${convertVW(16)};
  `}
`;

const CardMessage = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    left: 0;
    bottom: ${convertVW(80)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: #eee;
    width: 100%;
    padding: ${convertVW(24)} ${convertVW(16)} ${convertVW(24)};
    gap: ${convertVW(16)};
  `}
`;

const CardUserName = styled.div`
  ${({ theme: { convertVW } }) => css`
    width: 65%;

    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(16)};
    line-height: ${convertVW(22)};
    color: #959595;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      font-size: ${convertVW(11)};
    }
  `}
`;

const CardFooter = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${convertVW(80)};
    padding: ${convertVW(24)} 0 0;
    gap: ${convertVW(16)};
  `}
`;

const CardText = styled.div`
  ${({ theme: { convertVW } }) => css`
    width: 65%;

    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(16)};
    line-height: ${convertVW(22)};
    color: #959595;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      font-size: ${convertVW(11)};
    }
  `}
`;

const CardButtons = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${convertVW(16)};
  `}
`;

export {
  CardWrapper as Wrapper,
  CardInfoIcon as InfoIcon,
  CardEditIcon as EditIcon,
  CardImageWrapper as ImageWrapper,
  CardImage as Image,
  CardUserInfo as UserInfo,
  CardMessage as Message,
  CardUserName as UserName,
  CardFooter as Footer,
  CardText as Text,
  CardButtons as Buttons,
};
