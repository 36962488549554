/** Dependencies */
import React, { FC, useState } from 'react';

/** Components */
import Button from 'components/Button/Button';

/** Utilities */
import { capitalizeFirstLetters } from 'utilities/functions';

/** Styles */
import * as S from './ApproveModal.styled';

interface IProps {
  type: string;
  isApproved: boolean;
  designerName: string;
  designName: string;
  onConfirm: (message: string) => void;
  onCancel: () => void;
}

const ApproveModal: FC<IProps> = ({
  type,
  isApproved,
  designerName,
  designName,
  onConfirm,
  onCancel,
}) => {
  const [messageValue, setMessageValue] = useState('');

  return (
    <S.Wrapper>
      <S.Text>
        You are about to {type} {isApproved ? 'an' : 'a'}{' '}
        {isApproved === null
          ? 'pending'
          : isApproved
          ? 'approved'
          : 'disapproved'}{' '}
        design which is created by <span>{designerName}</span> and named{' '}
        <span>{designName}</span>!<br />
        <br />
        {type === 'disapprove' &&
          'If you have a message to ' +
            'designer please fill blank message area below!'}
      </S.Text>
      {type === 'disapprove' && (
        <S.Message
          name={'Message to designer'}
          placeholder={'message'}
          type={'textarea'}
          onChangeTextArea={(e) => setMessageValue(e.target.value)}
          defaultValue={messageValue}
        />
      )}
      <S.Buttons>
        <Button
          text={capitalizeFirstLetters(type)}
          onClick={() => onConfirm(messageValue)}
        />
        <Button text={'Cancel'} isOutline onClick={onCancel} />
      </S.Buttons>
    </S.Wrapper>
  );
};

export default ApproveModal;
