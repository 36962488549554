import styled, { css } from 'styled-components';
import { BreakPoint } from 'constants/BreakPoint';
import * as ButtonS from 'components/Button/Button.styled';

const ProtectedRouteWrapper = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  width: 100vw;

  @media only screen and (max-width: ${BreakPoint.Mobile}px) {
    grid-template-columns: 100%;
    grid-template-rows: 20% 80%;
  }
`;

const ProtectedRouteLogoWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #2c2c2c;
    border-bottom-right-radius: ${convertVW(48)};
    border-top-right-radius: ${convertVW(48)};

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      border-bottom-left-radius: ${convertVW(48)};
      border-bottom-right-radius: ${convertVW(48)};
      border-top-right-radius: 0;
    }
  `}
`;

const ProtectedRouteImage = styled.img`
  width: 30vw;

  @media only screen and (max-width: ${BreakPoint.Mobile}px) {
    width: 60vw;
  }
`;

const ProtectedRouteContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProtectedRouteButtonsContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${convertVW(12)};

    ${ButtonS.Wrapper} {
      background-color: #66d996;
      border-color: #66d996;
      border-radius: ${convertVW(4)};
    }
  `}
`;

export {
  ProtectedRouteWrapper as Wrapper,
  ProtectedRouteLogoWrapper as LogoWrapper,
  ProtectedRouteImage as Image,
  ProtectedRouteContent as Content,
  ProtectedRouteButtonsContainer as ButtonsContainer,
};
