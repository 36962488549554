/** Dependencies */
import React, { FC, KeyboardEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

/** Components */
import Input from 'components/Input/Input';

/** Utilities */
import { toastOptions } from 'utilities/functions';

/** Styles */
import * as S from './PasteFigmaInput.styled';

interface IProps {
  name: string;
  isError?: boolean;
  onChange: (clipboardHTML: string) => void;
  value?: string;
}

const PasteFigmaInput: FC<IProps> = ({
  name,
  isError = false,
  onChange,
  value = '',
}) => {
  const [text, setText] = useState('');

  const handlePaste = (): void => {
    if (navigator.clipboard) {
      navigator.clipboard
        .read()
        .then((clipboardItems) => {
          clipboardItems.forEach((clipboardItem) => {
            if (clipboardItem.types.includes('text/html')) {
              clipboardItem.getType('text/html').then((blob) => {
                blob
                  .text()
                  .then((clipboardHTML) => {
                    setText('Figma Item');
                    onChange(clipboardHTML);
                    toast('Successfully pasted', toastOptions('👏'));
                  })
                  .catch((error) => {
                    console.error('Failed to read clipboard HTML:', error);
                  });
              });
            }
          });
        })
        .catch((error) => {
          console.error('Failed to read clipboard:', error);
        });
    } else {
      console.error('Clipboard API not supported');
    }
  };

  const handleRemove = (): void => {
    setText('');
    onChange('');
    toast('Figma object removed', toastOptions('🫧'));
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.keyCode === 8) handleRemove();
  };

  useEffect(() => {
    if (value?.length > 0) setText('Figma Item');
    else setText('');
  }, [value]);

  return (
    <S.Wrapper>
      <Input
        name={name}
        onPaste={handlePaste}
        defaultValue={text}
        isError={isError}
        placeholder={'Paste here'}
        onKeyDown={handleKeyDown}
      />
      {text && <S.RemoveButton onClick={handleRemove}>X</S.RemoveButton>}
    </S.Wrapper>
  );
};

export default PasteFigmaInput;
