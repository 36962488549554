import styled, { css } from 'styled-components';
import { BreakPoint } from 'constants/BreakPoint';

const TagWrapper = styled.div<{ isActive: boolean; isVisible: boolean }>`
  ${({ theme: { convertVW }, isActive, isVisible }) => css`
    padding: ${convertVW(8)} ${convertVW(16)};
    background-color: ${isActive ? '#000000' : '#FAFAF9'};
    color: ${isActive ? '#fff' : '#000'};
    border-radius: ${convertVW(9999)};
    cursor: pointer;
    height: fit-content;
    border: ${convertVW(1)} solid rgba(0, 0, 0, 0.1);
    border-radius: ${convertVW(90)};
    opacity: ${isVisible ? 1 : 0.2};

    font-family: DM Sans, sans-serif;
    font-size: ${convertVW(16)};
    user-select: none;
    font-weight: 500;
    white-space: nowrap;

    &:hover {
      background-color: ${isActive ? '#000000' : '#626262'};
      color: #fff;
    }

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      &:hover {
        background-color: ${isActive ? '#000000' : '#FAFAF9'};
        color: ${isActive ? '#fff' : '#000'};
      }
    }
  `}
`;

export { TagWrapper as Wrapper };
