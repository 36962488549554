/** Dependencies */
import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

/** Outseta */
import { loadOutseta } from './Outseta';

/** Features */
import { IPlan } from 'features/Sidebar/types';

/** Constants */
import { ROUTE } from 'constants/Routes';
import { ENDPOINTS } from 'constants/EndPoints';

/** Api */
import { getRequest, postRequest, putRequest } from 'api/apiClient';

/** Utilities */
import { compareUsers, flattenResponse } from 'utilities/functions';

interface IOutsetaRef {
  setAccessToken: (token: string) => void;
  getAccessToken: () => string | null;
  getUser: () => Promise<any>;
  on: (event: string, handler: () => void) => void;
  auth: {
    open: (options: any) => void;
  };
  profile: {
    open: (options: any) => void;
  };
}

interface IAuthContextType {
  plans: IPlan[];
  user: any;
  isUserDesigner: boolean | null;
  isUserDefault: boolean | null;
  isUserAdmin: boolean | null;
  isLoading: boolean;
  logout: () => void;
  openLogin: (options?: any) => void;
  openSignup: (options?: any) => void;
  openProfile: (options?: any) => void;
}

const AuthContext = createContext<IAuthContextType>({
  plans: [],
  user: null,
  isUserDesigner: false,
  isUserDefault: false,
  isUserAdmin: false,
  isLoading: true,
  logout: () => ({}),
  openLogin: () => ({}),
  openSignup: () => ({}),
  openProfile: () => ({}),
});

export function useAuth(): IAuthContextType {
  return useContext(AuthContext);
}

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}): ReactElement {
  const outsetaRef = useRef<IOutsetaRef | null>(null);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [status, setStatus] = useState<'init' | 'ready'>('init');
  const [user, setUser] = useState<any>(null);
  const [plans, setPlans] = useState<any[]>([]);
  const [isUserDesigner, setIsUserDesigner] = useState<boolean | null>(null);
  const [isUserDefault, setIsUserDefault] = useState<boolean | null>(null);
  const [isUserAdmin, setIsAdmin] = useState<boolean | null>(null);

  const getPlans = async (): Promise<void> => {
    const response = await axios.get(
      'https://quickdesign.outseta.com/api/v1/billing/plans'
    );

    setPlans(response.data.items);
  };

  const updateUser = async (): Promise<void> => {
    // Fetch the current user data from outseta
    const outsetaUser = await outsetaRef.current?.getUser();
    // Update user state
    setUser(outsetaUser);
    // Make sure status = ready
    setStatus('ready');
  };

  const handleOutsetaUserEvents = (onEvent: () => void): void => {
    // Subscribe to user related events
    // with onEvent function
    const outseta = outsetaRef.current;
    outseta?.on('subscription.update', onEvent);
    outseta?.on('profile.update', onEvent);
    outseta?.on('account.update', onEvent);
  };

  const logout = (): void => {
    navigate(ROUTE.ROOT);
    // Unset access token
    outsetaRef.current?.setAccessToken('');
    // and remove user state
    setUser(null);
  };

  const openLogin = async (options?: any): Promise<void> => {
    outsetaRef.current?.auth.open({
      widgetMode: 'login|register',
      authenticationCallbackUrl: window.location.href,
      ...options,
    });
  };

  const openSignup = async (options?: any): Promise<void> => {
    outsetaRef.current?.auth.open({
      widgetMode: 'register',
      authenticationCallbackUrl: window.location.href,
      ...options,
    });
  };

  const openProfile = async (options?: any): Promise<void> => {
    outsetaRef.current?.profile.open({ tab: 'profile', ...options });
  };

  useEffect(() => {
    const init = async (): Promise<void> => {
      // Await injection of the script
      outsetaRef.current = await loadOutseta();

      // Set up handling of user related events
      handleOutsetaUserEvents(updateUser);

      // Get the access token from the callback url
      const accessToken = searchParams.get('access_token');

      if (accessToken) {
        // If there is an acccess token present
        // pass it along to Outseta
        outsetaRef.current?.setAccessToken(accessToken);

        // and clean up
        setSearchParams({});
      }

      if (outsetaRef.current?.getAccessToken()) {
        // Outseta initialized with an authenticated user.
        updateUser();
      } else {
        // Outseta initialized without authenticated user.
        setStatus('ready');
      }
    };

    init();

    return () => {
      // Clean up user related event subscriptions
      handleOutsetaUserEvents(() => ({}));
    };
  }, [searchParams]);

  useEffect(() => {
    void getPlans();
  }, []);

  useEffect(() => {
    if (user) {
      const selectedPlan = plans.find(
        (plan) => plan.Uid === user?.Account?.CurrentSubscription?.Plan?.Uid
      );
      const isDesigner = selectedPlan?.PlanFamily.Name === 'Designers';

      const isDefault = selectedPlan?.PlanFamily.Name === 'Default';

      const isAdmin = selectedPlan?.PlanFamily.Name === 'Admins';

      const isPro = selectedPlan?.Name === 'Pro';
      const isBasic = selectedPlan?.Name === 'Basic';
      const isFree = selectedPlan?.Name === 'Free Plan';

      setIsUserDesigner(isDesigner);
      setIsUserDefault(isDefault);
      setIsAdmin(isAdmin);

      void getRequest(
        isDesigner
          ? ENDPOINTS.DESIGNER_USER_WITH_UID(user.Uid)
          : ENDPOINTS.SYSTEM_USER_WITH_UID(user.Uid)
      )
        .then((response) => {
          const strapiUser = flattenResponse(response.data.data)[0];

          const hasUserChanged = compareUsers({
            user: strapiUser,
            outsetaUser: user,
          });

          if (hasUserChanged)
            void putRequest(
              isDesigner
                ? ENDPOINTS.DESIGNER_USER_WITH_ID(strapiUser.id)
                : ENDPOINTS.SYSTEM_USER_WITH_ID(strapiUser.id),
              {
                copyCount: isPro ? 10000 : isBasic ? 10 : isFree ? 10 : null,
                plan: selectedPlan.Name,
                fullName: user.FullName,
                email: user.Email,
              }
            ).catch((err) => console.log(err));
        })
        .catch(() => {
          void postRequest(
            isDesigner ? ENDPOINTS.DESIGNER_USERS : ENDPOINTS.SYSTEM_USERS,
            {
              ...(isDesigner
                ? {}
                : {
                    likes: [],
                    plan: selectedPlan.Name,
                    copyCount: isPro
                      ? 10000
                      : isBasic
                      ? 10
                      : isFree
                      ? 10
                      : null,
                  }),
              fullName: user.FullName,
              email: user.Email,
              userId: user.Uid,
            }
          );
        });
    }
  }, [plans, user]);

  return (
    <AuthContext.Provider
      value={{
        plans,
        user,
        isUserDesigner,
        isUserDefault,
        isUserAdmin,
        isLoading: status !== 'ready',
        logout,
        openLogin,
        openSignup,
        openProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
