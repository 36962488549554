/** Dependencies */
import React, { FC } from 'react';

/** Styles */
import * as S from './Button.styled';

interface IProps {
  key?: string;
  text?: string;
  icon?: string;
  bgColor?: string;
  isOutline?: boolean;
  isDisabled?: boolean;
  onClick?: (e?: any) => void;
}

const Button: FC<IProps> = ({
  key,
  text,
  icon,
  bgColor,
  isOutline = false,
  isDisabled = false,
  onClick,
}) => {
  return (
    <S.Wrapper
      isDisabled={isDisabled}
      isRounded={!text}
      onClick={onClick}
      isOutline={isOutline}
      bgColor={bgColor}
    >
      {text && <S.Text isOutline={isOutline}>{text}</S.Text>}
      {icon && <S.Icon src={icon} alt={key} />}
    </S.Wrapper>
  );
};

export default Button;
