import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/App.store';

interface IDesignCounts {
  approvedDesignsCount: number;
  disapprovedDesignsCount: number;
  pendingDesignsCount: number;
  archivedDesignsCount?: number;
}

interface ISidebarState {
  isSidebarVisible: boolean;
  isDark: boolean;
  adminDesignCounts: IDesignCounts;
  designerDesignCounts: IDesignCounts;
  isCountsRequestTriggered: boolean;
}

const initialState: ISidebarState = {
  isSidebarVisible: false,
  isDark: true,
  adminDesignCounts: {
    approvedDesignsCount: 0,
    disapprovedDesignsCount: 0,
    pendingDesignsCount: 0,
    archivedDesignsCount: 0,
  },
  designerDesignCounts: {
    approvedDesignsCount: 0,
    disapprovedDesignsCount: 0,
    pendingDesignsCount: 0,
  },
  isCountsRequestTriggered: false,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarVisibility: (state, action) => {
      state.isSidebarVisible = action.payload;
    },
    setThemeIsDark: (state, action) => {
      state.isDark = action.payload;
    },
    setAdminDesignCounts: (state, action) => {
      state.adminDesignCounts = action.payload;
    },
    setDesignerDesignCounts: (state, action) => {
      state.designerDesignCounts = action.payload;
    },
    setIsCountsRequestTriggered: (state, action) => {
      state.isCountsRequestTriggered = action.payload;
    },
  },
});

export const {
  setSidebarVisibility,
  setThemeIsDark,
  setAdminDesignCounts,
  setDesignerDesignCounts,
  setIsCountsRequestTriggered,
} = sidebarSlice.actions;

export const selectIsSidebarVisible = (state: RootState): boolean =>
  state.sidebar.isSidebarVisible;

export const selectThemeIsDark = (state: RootState): boolean =>
  state.sidebar.isDark;

export const selectAdminDesignCounts = (state: RootState): IDesignCounts =>
  state.sidebar.adminDesignCounts;

export const selectDesignerDesignCounts = (state: RootState): IDesignCounts =>
  state.sidebar.designerDesignCounts;

export const selectIsCountsRequestTriggered = (state: RootState): boolean =>
  state.sidebar.isCountsRequestTriggered;

export default sidebarSlice.reducer;
