import styled, { css } from 'styled-components';

const TagsWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: ${convertVW(12)};
  `}
`;

const TagsTitle = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${convertVW(12)};
    cursor: pointer;
  `}
`;

const TagsTitleIcon = styled.img`
  ${({ theme: { convertVW } }) => css`
    max-width: ${convertVW(14)};
    max-height: ${convertVW(14)};
  `}
`;

const TagsTitleText = styled.div`
  ${({ theme: { convertVW } }) => css`
    user-select: none;
    font-family: 'DM Sans', sans-serif;
    font-size: ${convertVW(14)};
    line-height: ${convertVW(20)};
    color: #6b7280;
    text-transform: uppercase;
  `}
`;

const TagsContent = styled.div<{ isMinimized: boolean }>`
  ${({ theme: { convertVW }, isMinimized }) => css`
    display: flex;
    height: ${isMinimized ? 0 : convertVW(40)};
    align-items: center;
    width: 100%;
    overflow: scroll;
    gap: ${convertVW(8)};
    transition: height 1s ease;

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export {
  TagsWrapper as Wrapper,
  TagsTitle as Title,
  TagsTitleIcon as TitleIcon,
  TagsTitleText as TitleText,
  TagsContent as Content,
};
