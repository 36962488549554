import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Helper function to handle GET requests
export const getRequest = async (
  endpoint: string,
  params: any = {}
): Promise<any> => {
  try {
    return await axios.get(`${API_URL}${endpoint}`, { params });
  } catch (error) {
    console.error(`Error fetching ${endpoint}:`, error);
    throw error;
  }
};

// Helper function to handle POST requests
export const postRequest = async (
  endpoint: string,
  data: any,
  formData?: FormData
): Promise<any> => {
  try {
    const dt = formData ? formData : { data };
    return await axios.post(`${API_URL}${endpoint}`, dt);
  } catch (error) {
    console.error(`Error posting to ${endpoint}:`, error);
    throw error;
  }
};

// Helper function to handle PUT requests
export const putRequest = async (endpoint: string, data: any): Promise<any> => {
  try {
    return await axios.put(`${API_URL}${endpoint}`, { data });
  } catch (error) {
    console.error(`Error updating ${endpoint}:`, error);
    throw error;
  }
};
