import styled, { css } from 'styled-components';
import { BreakPoint } from 'constants/BreakPoint';

const ProfileWrapper = styled.a`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${convertVW(12)};

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      gap: ${convertVW(8)};
    }
  `}
`;

const ProfileImageWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: relative;
    width: ${convertVW(36)};
    height: ${convertVW(36)};
    border-radius: 50%;
    overflow: hidden;
  `}
`;

const ProfileImage = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`;

const ProfileSettings = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${convertVW(8)};
    cursor: pointer;

    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(12)};
    line-height: ${convertVW(16)};
    font-weight: 500;
    color: #a1a1aa;
    word-spacing: 0;

    &:hover {
      text-decoration: underline;
    }

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      display: none;
    }
  `}
`;

const ProfileContentWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: ${convertVW(4)};
  `}
`;

const ProfileSalute = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(15)};
    line-height: ${convertVW(15)};
    font-weight: 600;
    color: #3f3f46;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      display: none;
    }
  `}
`;

const ProfileSettingsIcon = styled.img`
  ${({ theme: { convertVW } }) => css`
    height: ${convertVW(17)};

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      height: ${convertVW(24)};
    }
  `}
`;

const ProfileSignInWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: ${convertVW(8)};
    cursor: pointer;

    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(12)};
    line-height: ${convertVW(16)};
    font-weight: 500;
    color: #a1a1aa;
    word-spacing: 0;

    &:hover {
      text-decoration: underline;
    }

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      font-size: ${convertVW(10)};

      &:hover {
        text-decoration: none;
      }
    }
  `}
`;

export {
  ProfileWrapper as Wrapper,
  ProfileImageWrapper as ImageWrapper,
  ProfileImage as Image,
  ProfileContentWrapper as ContentWrapper,
  ProfileSalute as Salute,
  ProfileSettings as Settings,
  ProfileSettingsIcon as SettingsIcon,
  ProfileSignInWrapper as SignInWrapper,
};
