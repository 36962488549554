import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/App.store';

export interface ISortItem {
  key: string;
  order: string;
  text: string;
}

const initialState: ISortItem = {
  key: '',
  order: '',
  text: '',
};

export const sortSlice = createSlice({
  name: 'sort',
  initialState,
  reducers: {
    setSortItem: (state, action: PayloadAction<ISortItem>) => {
      state.key = action.payload.key;
      state.order = action.payload.order;
      state.text = action.payload.text;
    },
  },
});

export const { setSortItem } = sortSlice.actions;

export const selectSortItem = (state: RootState): ISortItem => state.sort;

export default sortSlice.reducer;
