/** Dependencies */
import React, { FC } from 'react';

/** Styles */
import * as S from 'components/ToggleItem/ToggleItem.styled';

interface IProps {
  text: string;
  isSelected?: boolean;
  onClick?: () => void;
}

const ToggleItem: FC<IProps> = ({ text, isSelected = false, onClick }) => {
  return (
    <S.Wrapper onClick={onClick} isSelected={isSelected}>
      {text}
    </S.Wrapper>
  );
};

export default ToggleItem;
