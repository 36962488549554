import { configureStore } from '@reduxjs/toolkit';

import sortReducer from 'store/slices/Sort.slice';
import paginationReducer from 'store/slices/Pagination.slice';
import sidebarReducer from 'store/slices/Sidebar.slice';
import headerReducer from 'store/slices/Header.slice';
import modalReducer from 'store/slices/Modal.slice';

export const store = configureStore({
  reducer: {
    sort: sortReducer,
    pagination: paginationReducer,
    sidebar: sidebarReducer,
    header: headerReducer,
    modal: modalReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
