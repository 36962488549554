/** Dependencies */
import React, { FC } from 'react';

/** Image */
import LoginIcon from 'assets/images/svg/login-icon.svg';
import LogoutIcon from 'assets/images/svg/logout-icon.svg';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Hooks */
import { useDeviceDetect } from 'hooks/UseDeviceDetect';

/** Styles */
import * as S from 'features/Profile/Profile.styled';

interface IProps {
  image: string;
}

const Profile: FC<IProps> = ({ image }) => {
  const { isMobile } = useDeviceDetect();
  const { user, logout, openLogin, openProfile, isLoading } = useAuth();

  const isUserConnected = !!user;

  const handleLoginClick = (options?: any): void =>
    !isUserConnected ? openLogin(options) : logout();

  return (
    <S.Wrapper onClick={!isMobile ? () => ({}) : openProfile}>
      {isLoading ? (
        <S.SignInWrapper>Loading...</S.SignInWrapper>
      ) : (
        <>
          {isUserConnected && (
            <S.ImageWrapper>
              <S.Image
                src={user?.ProfileImageS3Url ? user.ProfileImageS3Url : image}
                alt={'profile'}
              />
            </S.ImageWrapper>
          )}
          <S.ContentWrapper>
            {isUserConnected && (
              <>
                <S.Salute>{user.FullName}</S.Salute>
                <S.Settings onClick={openProfile}>Manage Account</S.Settings>
              </>
            )}
            <S.SignInWrapper onClick={handleLoginClick}>
              {isUserConnected ? (isMobile ? '' : 'Logout') : 'Sign In'}
              <S.SettingsIcon
                src={isUserConnected ? LogoutIcon : LoginIcon}
                alt={isUserConnected ? 'Logout' : 'Sign In'}
              />
            </S.SignInWrapper>
          </S.ContentWrapper>
        </>
      )}
    </S.Wrapper>
  );
};

export default Profile;
